import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import GroupIcon from "@mui/icons-material/Group";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axiosConfig from "../../axiosConfig";
import EditPermissionButton from "../../components/Management/EditPermissionButton";
import { jaJP } from "@mui/x-data-grid/locales";

interface SearchInput {
  fullname: string;
  username: string;
  [key: string]: string;
}
const AuthorityManagement: React.FC = () => {
  const getPermissionButtonColor = (permission: string) => {
    switch (permission) {
      case "admin":
        return "#E8DEF9";
      case "checker":
        return "#F9EEED";
      default:
        return "#FFE169";
    }
  };
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "fullname",
      headerName: "氏名",
      width: 160,
    },
    { field: "username", headerName: "ログインID", width: 200 },
    { field: "type", headerName: "権限", width: 200 },
    {
      field: "roles",
      headerName: "ロール",
      width: 600,
      renderCell: (params) => {
        const keysPermission = Object.keys(params.value) ?? [];
        return (
          <Box sx={{ height: "100%" }} display="flex" alignItems="center">
            {params.row.type !== "顧客" && (
              <EditPermissionButton
                id={params.id}
                options={params.value}
                fetchData={fetchData}
                role={params.row.type}
              />
            )}
            {keysPermission.map((permission: string, index: any) => {
              if (params.value[permission] === true)
                return (
                  <Button
                    key={index}
                    variant="contained"
                    size="small"
                    style={{ marginRight: 10 }}
                    disableElevation={true}
                    sx={{
                      backgroundColor: getPermissionButtonColor(permission),
                      color: "#666666",
                      "&:hover": {
                        backgroundColor: getPermissionButtonColor(permission),
                      },
                    }}
                  >
                    {permission === "admin" ? "管理者" : "チェック担当"}
                  </Button>
                );
              return null;
            })}
          </Box>
        );
      },
    },
  ];
  const [data, setData] = useState([]);
  //Left navigation
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showSearchContent, setShowSearchContent] = useState(true);
  const [searchInput, setSearchInput] = useState<Partial<SearchInput>>({
    fullname: "",
    username: "",
  });
  const searchUrl = useRef<string>("");
  const filterRole = useRef<string>("");
  const [fullnameList, setFullnameList] = useState<string[]>([]);
  const [usernameList, setUsernameList] = useState<string[]>([]);

  const fetchData = async () => {
    let url = "";
    if (filterRole.current === "" && searchUrl.current === "") url = "users";
    if (filterRole.current !== "") url = `users?role=${filterRole.current}`;
    if (searchUrl.current !== "") url = `users?${searchUrl.current}`;
    if (filterRole.current !== "" && searchUrl.current !== "")
      url = `users?role=${filterRole.current}&${searchUrl.current}`;

    await axiosConfig.get(url).then((res) => {
      setFullnameList(res.data.data.map((item: any) => item.fullname));
      setUsernameList(res.data.data.map((item: any) => item.username));
      setData(res.data.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangeSearchInput = (
    e: SyntheticEvent,
    value: string,
    field: string
  ) => {
    setSearchInput((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleListItemClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    role: string
  ) => {
    filterRole.current = role;
    setSelectedIndex(index);
    setSearchInput({
      fullname: "",
      username: "",
    });
    searchUrl.current = "";
    await fetchData();
  };

  const handleSearch = async () => {
    let searchUrlBase = "";
    const keys = Object.keys(searchInput);
    keys.forEach((key) => {
      if (searchInput[key] !== "") {
        if (searchUrlBase.length === 0)
          searchUrlBase += `${key}=${searchInput[key]}`;
        else searchUrlBase += `&${key}=${searchInput[key]}`;
      }
    });
    searchUrl.current = searchUrlBase;
    await fetchData();
  };

  return (
    <div className="flex flex-col">
      <Header pageTitle="権限管理"></Header>
      <Grid container sx={{ height: "90vh" }} className="pb-6">
        <Grid item xs={1.5} className="py-2 bg-gray-100 h-full">
          <List component="nav" sx={{ color: "#3E6EB4" }}>
            <ListItemButton
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0, "")}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#3E6EB4",
                  color: "#fff",
                },
              }}
            >
              <GroupsIcon className="mr-4" />
              <ListItemText primary="すべて" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1, "admin")}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#3E6EB4",
                  color: "#fff",
                },
              }}
            >
              <GroupIcon className="mr-4" />
              <ListItemText primary="管理者" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2, "checker")}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#3E6EB4",
                  color: "#fff",
                },
              }}
            >
              <GroupIcon className="mr-4" />
              <ListItemText primary="チェック担当" />
            </ListItemButton>
          </List>
        </Grid>
        <Grid
          item
          xs={10.5}
          className="h-full pb-12"
          sx={{ overflowX: "hidden", overflowY: "scroll" }}
        >
          <div className="mt-4 px-4">
            <Card
              className="mx-auto w-full"
              sx={{ border: 1, borderColor: "grey.300" }}
            >
              <CardHeader
                sx={{ bgcolor: "#F5F5F5", paddingY: "5px" }}
                subheader={
                  <Button
                    variant="text"
                    sx={{ padding: 0, color: "black" }}
                    disableRipple={true}
                    onClick={() => setShowSearchContent(!showSearchContent)}
                  >
                    検索エンジン
                  </Button>
                }
              />
              {showSearchContent && (
                <CardContent sx={{ paddingY: "5px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <h3>氏名</h3>
                      <Autocomplete
                        freeSolo
                        className="mt-1"
                        size="small"
                        disableClearable
                        renderInput={(params) => <TextField {...params} />}
                        options={fullnameList}
                        value={searchInput.fullname}
                        onInputChange={(e, value) =>
                          handleChangeSearchInput(e, value, "fullname")
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <h3>ログインID</h3>
                      <Autocomplete
                        freeSolo
                        className="mt-1"
                        size="small"
                        disableClearable
                        renderInput={(params) => <TextField {...params} />}
                        options={usernameList}
                        value={searchInput.username}
                        onInputChange={(e, value) =>
                          handleChangeSearchInput(e, value, "username")
                        }
                      />
                    </Grid>
                  </Grid>
                  <Stack className="mt-2">
                    <div className="ml-auto">
                      <Button
                        variant="contained"
                        sx={{ paddingX: "30px" }}
                        onClick={handleSearch}
                      >
                        検索
                      </Button>
                    </div>
                  </Stack>
                </CardContent>
              )}
            </Card>
            <Card
              className="mx-auto w-full mt-5"
              sx={{ border: 1, borderColor: "grey.300" }}
            >
              <CardHeader
                sx={{ bgcolor: "#F5F5F5", paddingY: "18px", paddingX: "12px" }}
              />
              <CardContent>
                <DataGrid
                  localeText={
                    jaJP.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={data}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pageSizeOptions={[10, 20, 30]}
                  disableColumnMenu
                  disableColumnSorting
                  disableRowSelectionOnClick
                />
                {data.length === 0 && (
                  <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
                    データがありません
                  </Typography>
                )}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
      <Footer></Footer>
    </div>
  );
};
export default AuthorityManagement;
