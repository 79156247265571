// src/pages/Home.tsx
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Container,
  ThemeProvider,
  createTheme,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// redux
import {
  selectManagementProcess,
  changeProcess,
  changeDisplay,
  managementProcess,
} from "../features/managementProcess/managementProcessSlice";
import { useAppSelector, useAppDispatch } from "../app/hooks";

// カスタイマイズしたもの
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";

const OrderAcceptanceCompletion: React.FC = () => {
  // ナビゲーション
  const navigate = useNavigate();
  const managementProcess: managementProcess = useAppSelector(
    selectManagementProcess
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(changeDisplay("OrderAcceptanceCompletion"));
  }, [dispatch]);

  // 現在のタブを閉じる関数
  const handleReturnToTop = () => {
    // 現在のタブを閉じる
    navigate("/");
  };

  return (
    <>
      <Header pageTitle="発注完了" />
      <Container style={{ marginTop: "16px", marginBottom: "100px" }}>
        <Box sx={{ textAlign: "center", marginTop: "40px" }}>
          <Typography variant="h4">発注が完了しました。</Typography>
          <Box sx={{ textAlign: "center", marginTop: "40px" }}>
            <Button
              variant="contained"
              color="info"
              onClick={handleReturnToTop}
            >
              トップページに戻る
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default OrderAcceptanceCompletion;
