import React, { SyntheticEvent, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axiosConfig from "../../axiosConfig";
import { jaJP } from "@mui/x-data-grid/locales";

interface SearchInput {
  fullname: string;
  username: string;
  [key: string]: string;
}
const EmployeeManagement: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 120 },
    {
      field: "fullname",
      headerName: "氏名",
      width: 300,
    },
    { field: "username", headerName: "ログインID", width: 250 },
    {
      field: "email",
      headerName: "メールアドレス",
      width: 280,
    },
    {
      field: "phone_number",
      headerName: "電話番号",
      width: 200,
    },
    {
      field: "type",
      headerName: "権限",
      width: 190,
    },
  ];
  const [data, setData] = useState([]);
  const [showSearchContent, setShowSearchContent] = React.useState(true);
  const [searchInput, setSearchInput] = useState<Partial<SearchInput>>({
    fullname: "",
    username: "",
  });
  const [fullnameList, setFullnameList] = useState<string[]>([]);
  const [usernameList, setUsernameList] = useState<string[]>([]);

  const fetchData = async (search = "") => {
    let url = "users/customer";
    if (search !== "") {
      url += `?${search}`;
    }
    await axiosConfig.get(url).then((res) => {
      setFullnameList(res.data.data.map((item: any) => item.fullname));
      setUsernameList(res.data.data.map((item: any) => item.username));
      setData(res.data.data);
    });
  };

  const handleChangeSearchInput = (
    e: SyntheticEvent,
    value: string,
    field: string
  ) => {
    setSearchInput((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSearch = async () => {
    let searchUrl = "";
    const keys = Object.keys(searchInput);
    keys.forEach((key) => {
      if (searchInput[key] !== "") {
        if (searchUrl.length === 0) searchUrl += `${key}=${searchInput[key]}`;
        else searchUrl += `&${key}=${searchInput[key]}`;
      }
    });
    await fetchData(searchUrl);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex flex-col">
      <Header pageTitle="顧客アカウント管理"></Header>
      <Grid
        className="pt-4 pb-14"
        height={"90vh"}
        sx={{ overflow: "hidden", overflowY: "scroll" }}
      >
        <Card
          className="mx-auto"
          sx={{ border: 1, borderColor: "grey.300", width: "90vw" }}
        >
          <CardHeader
            sx={{ bgcolor: "#F5F5F5", paddingY: "5px" }}
            subheader={
              <Button
                variant="text"
                sx={{ padding: 0, color: "black" }}
                disableRipple={true}
                onClick={() => setShowSearchContent(!showSearchContent)}
              >
                検索エンジン
              </Button>
            }
          />
          {showSearchContent && (
            <CardContent sx={{ paddingY: "5px" }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <h3>氏名</h3>
                  <Autocomplete
                    freeSolo
                    className="mt-1"
                    size="small"
                    disableClearable
                    renderInput={(params) => <TextField {...params} />}
                    options={fullnameList}
                    value={searchInput.fullname}
                    onInputChange={(e, value) =>
                      handleChangeSearchInput(e, value, "fullname")
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <h3>ログインID</h3>
                  <Autocomplete
                    freeSolo
                    className="mt-1"
                    size="small"
                    disableClearable
                    renderInput={(params) => <TextField {...params} />}
                    options={usernameList}
                    value={searchInput.username}
                    onInputChange={(e, value) =>
                      handleChangeSearchInput(e, value, "username")
                    }
                  />
                </Grid>
              </Grid>
              <Stack className="mt-2">
                <div className="ml-auto">
                  <Button
                    variant="contained"
                    sx={{ paddingX: "30px" }}
                    onClick={handleSearch}
                  >
                    検索
                  </Button>
                </div>
              </Stack>
            </CardContent>
          )}
        </Card>
        <Card
          className="mx-auto mt-5 mb-4"
          sx={{ width: "90vw", border: 1, borderColor: "grey.300" }}
        >
          <CardHeader
            sx={{ bgcolor: "#F5F5F5", paddingY: "18px", paddingX: "12px" }}
          />
          <CardContent>
            <DataGrid
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              rows={data}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 20, 30]}
              disableColumnMenu
              disableColumnSorting
              disableRowSelectionOnClick
            />
            {data.length === 0 && (
              <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
                データがありません
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Footer></Footer>
    </div>
  );
};
export default EmployeeManagement;
