import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { estimationAmountState } from '../estimationAmount/estimationAmountSlice';
import { useAppSelector, useAppDispatch } from "../../app/hooks";

interface isFileSelectedState {
  selectedFileName: string | null;
}

// 初期状態の定義
const initialState: isFileSelectedState = {
  selectedFileName: null,
};

export const isFileSelectedSlice = createSlice({
  name: 'isEstimationAmountDisplay',
  initialState,
  reducers: {
    fileSelected: (state, action: PayloadAction<isFileSelectedState>) => {
      // 新しい値を取得
      state = action.payload;
      return state;
    },
  },
});
// 読み出し用
export const selectIsFileSelected = (state: RootState) => state.isFileSelected;
// action用
export const { fileSelected } = isFileSelectedSlice.actions
export default isFileSelectedSlice.reducer;
