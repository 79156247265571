import React, { useRef } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

// PDF出力コンポーネント
const PDFExportComponent: React.FC = () => {
  const contentRef = useRef<HTMLDivElement>(null);

  // PDF生成関数
  const generatePDF = async () => {
    if (contentRef.current) {
      const canvas = await html2canvas(contentRef.current);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('output.pdf');
    }
  };

  return (
    <div>
      <div ref={contentRef}>
        {/* ここにPDFに出力したいHTML内容を追加 */}
        <h1>PDFに出力する内容</h1>
        <p>これはPDFに変換される段落です。</p>
      </div>
      <button onClick={generatePDF}>PDFを生成</button>
    </div>
  );
};

export default PDFExportComponent;