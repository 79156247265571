import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import axiosConfig from "../../axiosConfig";

type EditPermissionProps = {
  id: any;
  options: {};
  role: string;
  fetchData: () => void;
};

interface CheckedOptions {
  admin: boolean;
  checker: boolean;
}

const EditPermissionButton: React.FC<EditPermissionProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState<Partial<CheckedOptions>>(
    { ...props.options }
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setCheckedOptions((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCheckedOptions({ ...props.options });
    setOpen(false);
  };

  const handleSubmit = async () => {
    await axiosConfig({
      method: "patch",
      url: "users/update-roles",
      data: {
        ...checkedOptions,
        id: props.id,
      },
    }).then((res) => {
      if (res.status === 200) {
        props.fetchData();
        setOpen(false);
      }
    });
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        disableElevation={true}
        size="small"
        sx={{ minWidth: "20px", marginRight: "10px" }}
        onClick={handleClickOpen}
      >
        <AddIcon sx={{ width: "30px" }} />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"ロールを編集する"}</DialogTitle>
        <DialogContent>
          <FormGroup>
            {props.role === "社員" && (
              <FormControlLabel
                control={
                  <Checkbox
                    name="admin"
                    checked={checkedOptions.admin}
                    onChange={handleChange}
                  />
                }
                label="管理者"
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  name="checker"
                  checked={checkedOptions.checker}
                  onChange={handleChange}
                />
              }
              label="チェック担当"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{ fontWeight: "bold" }}
          >
            キャンセル
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            autoFocus
            sx={{ paddingX: "30px" }}
          >
            登録
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default EditPermissionButton;
