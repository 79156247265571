import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import axiosConfig from "../../axiosConfig";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Box,
  Typography,
  CardActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

interface ResetPasswordType {
  password: String;
  confirmPassword: String;
  [key: string]: String;
}

const ResetPassword: React.FC = () => {
  const [isValidLink, setIsValidLink] = useState(false);
  const initalResetPassword = {
    password: "",
    confirmPassword: "",
  };
  const [formData, setFormData] =
    useState<ResetPasswordType>(initalResetPassword);
  const [errorField, setErrorField] =
    useState<ResetPasswordType>(initalResetPassword);
  const [errorServer, setErrorServer] = useState<string>("");
  const navigate = useNavigate();
  const regexPassword: RegExp =
    /^[a-zA-Z0-9!"#$%&'()\-\^@\[\];:,./\=~|`{+*}<>?_]+$/;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nameField: ResetPasswordType = {
      password: "パスワード",
      confirmPassword: "確認のため再度パスワードを入力",
    };
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));

    if (e.target.value === "") {
      setErrorField((prevError) => {
        return { ...prevError, [e.target.name]: "必須項目です。" };
      });
    } else if (!regexPassword.test(e.target.value)) {
      setErrorField((prevError) => {
        return {
          ...prevError,
          [e.target.name]: `${nameField[e.target.name]}の値が不正です。`,
        };
      });
    } else {
      setErrorField((prevError) => {
        return { ...prevError, [e.target.name]: "" };
      });
    }
  };

  const handleClick = async () => {
    setErrorServer("");
    if (formData.confirmPassword !== formData.password) {
      setErrorField((prevError) => {
        return {
          ...prevError,
          confirmPassword: "入力されたパスワードが一致しません。",
        };
      });
    }
    if (JSON.stringify(errorField) === JSON.stringify(initalResetPassword)) {
      await axiosConfig({
        method: "",
        url: "",
        data: {
          ...formData,
        },
      })
        .then((res) => {
          if (res.status === 200 && res.data != null) {
          }
        })
        .catch((err) => {
          if (err.response && err.response.status) {
            setErrorServer(
              `エラーが発生しました。${err.response.status}システムの管理者にお問い合わせください。`
            );
          } else {
            setErrorServer(
              "エラーが発生しました。システムの管理者にお問い合わせください。"
            );
          }
        });
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("token") === "true") {
      setIsValidLink(true);
    }
  }, []);
  return (
    <div className="bg-gray-100 flex flex-col" style={{ minHeight: "100vh" }}>
      <Header hideTop></Header>
      <div className="my-auto">
        <div className="flex">
          <Card className="mx-auto" sx={{ width: "350px" }}>
            {isValidLink ? (
              <CardContent>
                <div className="mb-1 font-bold">パスワード</div>
                <TextField
                  name="password"
                  error={errorField.password.length > 0}
                  helperText={errorField.password}
                  size="small"
                  className="w-full"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <div className="mb-1 mt-2 font-bold">パスワードを確認 </div>
                <TextField
                  name="confirmPassword"
                  error={errorField.confirmPassword.length > 0}
                  helperText={errorField.confirmPassword}
                  size="small"
                  type="password"
                  className="w-full"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                {errorServer && (
                  <p style={{ color: "red", marginTop: "10px" }}>
                    {errorServer}
                  </p>
                )}
                <Box sx={{ "& button": { mt: 2 } }}>
                  <Button
                    className="w-full font-bold"
                    variant="contained"
                    onClick={handleClick}
                  >
                    メールを送信
                  </Button>
                  <Button
                    className="w-full font-bold "
                    variant="contained"
                    color="success"
                    onClick={() => navigate("/login")}
                  >
                    ログインに戻る
                  </Button>
                </Box>
              </CardContent>
            ) : (
              <Fragment>
                <CardContent>
                  <Typography variant="body2" color="error">
                    リンクは無効になっています。パスワードリセットを再リクエストするために、パスワードを忘れてしまったページに戻ってください。
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                    onClick={() => navigate("/forgot-password")}
                  >
                    <KeyboardReturnIcon className="mr-2" />
                    OK
                  </Button>
                </CardActions>
              </Fragment>
            )}
          </Card>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ResetPassword;
