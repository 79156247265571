import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import { useAppDispatch } from "../../app/hooks";
import { fetchEstimationAmount } from "./estimationAmountAPI";
import { format } from "date-fns";
import { addEstimationToList } from "../../features/estimationAmountList/estimationAmountListSlice";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export interface estimationAmountState {
  checkBox: boolean;
  estimationId: string;
  estimationItemId: string;
  estimationDateTime: string | null; // 日付型の estimationDateTime プロパティ
  estimationAmount: number | null; // 数値型の estimationAmount プロパティ
  estimationFileName: string | null; // 文字列型の estimationFileName プロパティ
  estimationS3FileName: string | null;
  status: "loading" | "succeeded" | "failed" | string;
}
const initialState: estimationAmountState = {
  checkBox: false,
  estimationId: "",
  estimationItemId: "",
  estimationDateTime: null,
  estimationAmount: null,
  estimationFileName: null,
  estimationS3FileName: null,
  status: "loading",
};
export const estimationAmountAsync = createAsyncThunk(
  "estimationAmount/fetchEstimationAmount",
  async (
    {
      fileName,
      fileNameOrigin,
      extension,
    }: { fileName: string; fileNameOrigin: string; extension: string },
    thunkAPI
  ) => {
    // 見積AIから非同期で値を取得する
    const body = {
      fileName: fileName,
      extension: extension,
    };
    let response: any;
    try {
      response = await axios.post(
        "https://6o556yrzjc.execute-api.ap-northeast-1.amazonaws.com/stage/create-estimate-tmp",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            // Accept: "application/json",
          },
        }
      );
      // console.log("Success", response);
    } catch (error) {
      console.log("Error", error);
    }
    const json_obj = JSON.parse(response["data"]);
    const price = Number(json_obj.price);
    const payload = {
      checkBox: false,
      estimationId: "UUID", //DBのUUIDが入る
      estimationItemId: "UUIDforItem",
      estimationDateTime: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      estimationAmount: price,
      // estimationAmount: 7000, // とりあえず7000円入れておく
      estimationFileName: fileNameOrigin,
      estimationS3FileName: fileName,
      status: "succeeded",
    };
    return payload;
  }
);

export const estimationAmountSlice = createSlice({
  name: "estimationAmount",
  initialState,
  reducers: {
    // estimate: (state) => {
    //   state.value = Math.floor(Math.random() * (3000 - 2000 + 1)) + 2000;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(estimationAmountAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(estimationAmountAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.checkBox = action.payload.checkBox;
        state.estimationId = action.payload.estimationId;
        state.estimationDateTime = action.payload.estimationDateTime;
        state.estimationAmount = action.payload.estimationAmount;
        state.estimationFileName = action.payload.estimationFileName;
        state.estimationS3FileName = action.payload.estimationS3FileName;
      })
      .addCase(estimationAmountAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// export const { estimate } = estimationAmountSlice.actions;
export const selectEstimationAmount = (state: RootState) =>
  state.estimationAmount;
export default estimationAmountSlice.reducer;
