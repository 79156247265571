import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";

const AdminRoute: React.FC = () => {
  const accountInfo = useAppSelector((state) => state.accountInfo);

  return accountInfo.roles.admin ? <Outlet /> : <Navigate to="/" />;
};

export default AdminRoute;
