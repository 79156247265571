import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import axiosConfig from "../../axiosConfig";
import { Button, Card, CardContent, TextField, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [errorField, setErrorField] = useState<string>("");
  const [errorServer, setErrorServer] = useState<string>("");
  const navigate = useNavigate();

  const rules = () => {
    setErrorField("");
    setErrorServer("");
    let isValid = true;
    const regexEmail: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (email === "") {
      setErrorField("必須項目です。");
      isValid = false;
    } else if (!regexEmail.test(email)) {
      setErrorField("パスワードの値が不正です。");
      isValid = false;
    }

    return isValid;
  };
  const handleClick = async () => {
    setErrorField("");
    setErrorServer("");
    if (rules()) {
      await axiosConfig({
        method: "",
        url: "",
        data: {
          email: email,
        },
      })
        .then((res) => {
          if (res.status === 200 && res.data != null) {
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status) {
            setErrorServer(
              `エラーが発生しました。${err.response.status}システムの管理者にお問い合わせください。`
            );
          } else {
            setErrorServer(
              "エラーが発生しました。システムの管理者にお問い合わせください。"
            );
          }
        });
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col" style={{ minHeight: "100vh" }}>
      <Header hideTop></Header>
      <div className="my-auto">
        <div className="flex">
          <Card className="mx-auto" sx={{ width: "350px" }}>
            <CardContent>
              <div className="mb-1 font-bold">メールアドレス</div>
              <TextField
                error={errorField.length > 0}
                helperText={errorField}
                size="small"
                className="w-full"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => rules()}
              />
              {errorServer && (
                <p style={{ color: "red", marginTop: "10px" }}>{errorServer}</p>
              )}
              <Box sx={{ "& button": { mt: 2 } }}>
                <Button
                  className="w-full font-bold"
                  variant="contained"
                  onClick={handleClick}
                >
                  メールを送信
                </Button>
                <Button
                  className="w-full font-bold "
                  variant="contained"
                  color="success"
                  onClick={() => navigate("/login")}
                >
                  ログインに戻る
                </Button>
              </Box>
            </CardContent>
          </Card>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ForgotPassword;
