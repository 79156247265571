import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import estimationAmountReducer from "../features/estimationAmount/estimationAmountSlice";
import estimationAmountListReducer from "../features/estimationAmountList/estimationAmountListSlice";
import isEstimationAmountDisplayReducer from "../features/isEstimationAmountDisplay/isEstimationAmountDisplaySlice";
import managementProcessReducer from "../features/managementProcess/managementProcessSlice";
import customerInfoReducer from "../features/customerInfo/customerInfoSlice";
import isFileSelectedReducer from "../features/isFileSelected/isFileSelectedSlice";
import accountInfoSlice from "../features/accountInfo/accountInfoSlice";

const rootReducer = combineReducers({
  estimationAmount: estimationAmountReducer,
  estimationAmountList: estimationAmountListReducer,
  estimationAmountDisplay: isEstimationAmountDisplayReducer,
  managementProcess: managementProcessReducer,
  customerInfo: customerInfoReducer,
  isFileSelected: isFileSelectedReducer,
  accountInfo: accountInfoSlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
