import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import axiosConfig from "../../axiosConfig";
import { Button, Card, CardContent, TextField, Link, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useAppDispatch } from "../../app/hooks";
import { setAccount } from "../../features/accountInfo/accountInfoSlice";

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [usernameError, setUsernameError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Cookies.get("access_token")) {
      navigate("/");
    }
  });

  const rules = () => {
    let isValid = true;
    const regexUsername: RegExp = /^[a-zA-Z0-9]+$/;
    const regexPassword: RegExp =
      /^[a-zA-Z0-9!"#$%&'()\-\^@\[\];:,./\=~|`{+*}<>?_]+$/;

    if (username.length === 0) {
      setUsernameError("必須項目です。");
      isValid = false;
    } else if (!regexUsername.test(username)) {
      setUsernameError("ログインIDの値が不正です。");
      isValid = false;
    }

    if (password === "") {
      setPasswordError("必須項目です。");
      isValid = false;
    } else if (!regexPassword.test(password)) {
      setPasswordError("パスワードの値が不正です。");
      isValid = false;
    }

    return isValid;
  };
  const handleLogin = async () => {
    setError("");
    setUsernameError("");
    setPasswordError("");
    if (rules()) {
      await axiosConfig({
        method: "post",
        url: "auth/login",
        data: {
          username: username,
          password: password,
        },
      })
        .then((res) => {
          if (res.status === 200 && res.data != null) {
            Cookies.set("access_token", res.data.access_token);
            dispatch(setAccount(res.data));
            navigate("/");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            if (err.response.status === 401)
              setError("ログインIDまたはパスワードが正しくありません。");
            else {
              setError(
                `エラーが発生しました。${err.response.status}システムの管理者にお問い合わせください。`
              );
            }
          } else {
            setError(
              "エラーが発生しました。システムの管理者にお問い合わせください。"
            );
          }
        });
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col" style={{ minHeight: "100vh" }}>
      <Header hideTop></Header>
      <div className="my-auto">
        <div className="flex">
          <Card className="mx-auto" sx={{ width: "350px" }}>
            <CardContent>
              <div className="mb-1 font-bold">ログインID</div>
              <TextField
                error={usernameError.length > 0}
                helperText={usernameError}
                size="small"
                className="w-full"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className="mt-4 mb-1 font-bold">パスワード </div>
              <TextField
                error={passwordError.length > 0}
                helperText={passwordError}
                type="password"
                size="small"
                className="w-full"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {error && (
                <p style={{ color: "red", marginTop: "10px" }}>{error}</p>
              )}
              <div className="mb-5 mt-4 text-xs">
                <Link href="/forgot-password" underline="always">
                  パスワードを忘れてしまった
                </Link>
              </div>
              <Box sx={{ "& button": { my: 1 } }}>
                <Button
                  className="w-full font-bold"
                  variant="contained"
                  onClick={handleLogin}
                >
                  ログイン
                </Button>
                <Button
                  className="w-full font-bold "
                  variant="contained"
                  color="success"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  新規登録
                </Button>
              </Box>
            </CardContent>
          </Card>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Login;
