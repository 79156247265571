import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import axiosConfig from "../../axiosConfig";

interface FormData {
  username: string;
  email: string;
  password: string;
  fullname: string;
  phone_number: string;
  confirmPassword: string;
  type: string;
  [key: string]: string | "";
}

interface objectFormData {
  [key: string]: {
    max: number | null;
    min: number | null;
    name: string | null;
    required: boolean | false;
  };
}

type CreateEmployeeProps = {
  handleFetchData: () => Promise<void>;
  type: string;
};

const CreateEmployeeButton: React.FC<CreateEmployeeProps> = (props) => {
  const initialForm = {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    fullname: "",
    phone_number: "",
    type: props.type,
  };
  const initialValidate = {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    fullname: "",
    phone_number: "",
  };
  const [errors, setErrors] = useState<Partial<FormData>>(initialValidate);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorServer, setErrorServer] = useState<string>("");
  const [formData, setFormData] = useState<FormData>(initialForm);
  const [open, setOpen] = React.useState(false);

  const validate = (field: string, value: string) => {
    let error = "";
    const regexUsername: RegExp = /^[a-zA-Z0-9]+$/;
    const regexPassword: RegExp =
      /^[a-zA-Z0-9!"#$%&'()\-\^@\[\];:,./\=~|`{+*}<>?_]+$/;
    const regexEmail: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const regexFullname: RegExp =
      /^(?!.*[\uff01-\uff0f\uff1a-\uff20\uff3b-\uff40\uff5b-\uff5e])[\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}a-zA-Z0-9ａ-ｚＡ-Ｚ０-９]+$/u;
    const regexPhone: RegExp = /^[0-9\\-]+$/;

    const validateKeys: objectFormData = {
      username: {
        max: 20,
        min: 3,
        name: "ログインID",
        required: true,
      },
      password: {
        max: 50,
        min: 8,
        name: "パスワード",
        required: true,
      },
      fullname: {
        max: 50,
        min: 3,
        name: "氏名",
        required: true,
      },
      phone_number: {
        max: null,
        min: null,
        name: "携帯電話番号",
        required: false,
      },
      confirmPassword: {
        max: 50,
        min: 8,
        name: "確認のため再度パスワードを入力",
        required: true,
      },
      email: {
        max: null,
        min: null,
        name: "メールアドレス",
        required: true,
      },
    };

    if (validateKeys[field].required && value.length === 0)
      error = "必須項目です。";
    else if (value !== "") {
      if (field === "confirmPassword" && value !== formData.password)
        error = "入力されたパスワードが一致しません。";

      if (
        validateKeys[field].max !== null &&
        validateKeys[field].min !== null
      ) {
        if (
          value.length > (validateKeys[field].max ?? 0) ||
          value.length < (validateKeys[field].min ?? 0)
        )
          error = `${validateKeys[field].min}～${validateKeys[field].max}文字以内で入力してください。`;
      }

      if (field === "phone_number") {
        const formatPhone = value.replace(/-/g, "");
        if (formatPhone.length !== 10) error = `10数字で入力してください。`;
      }

      if (
        (field === "username" && !regexUsername.test(value)) ||
        (field === "email" && !regexEmail.test(value)) ||
        (field === "password" && !regexPassword.test(value)) ||
        (field === "confirmPassword" && !regexPassword.test(value)) ||
        (field === "fullname" && !regexFullname.test(value)) ||
        (field === "phone_number" && !regexPhone.test(value))
      ) {
        error = `${validateKeys[field].name}の値が不正です。`;
      }
    }
    setErrors({ ...errors, [field]: error });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    validate(name, value);
  };

  //Handle Dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setErrors({});
    setErrorServer("");
    setIsSuccess(false);
    setFormData(initialForm);
    setOpen(false);
  };

  const validateRequiredAll = () => {
    let isValid = true;
    Object.keys(formData).forEach((key: string) => {
      if (key !== "phone_number" && formData[key].length === 0) {
        setErrors((errors) => {
          return { ...errors, [key]: "必須項目です。" };
        });
        isValid = false;
      }
    });
    return isValid;
  };

  //Handle Submit
  const handleSubmit = async () => {
    setErrorServer("");
    let isError = false;
    const valid = validateRequiredAll();

    for (const [, value] of Object.entries(errors)) {
      if (value !== "") {
        isError = true;
      }
    }

    if (!isError && valid)
      await axiosConfig({
        method: "post",
        url: "auth/register",
        data: {
          ...formData,
          phone_number: formData.phone_number.replace(/-/g, ""),
        },
      })
        .then((res) => {
          if (res.status === 201 && res.data != null) {
            setFormData({ ...initialForm });
            props.handleFetchData();
            setErrors({});
            setErrorServer("");
            setIsSuccess(true);
            setTimeout(() => setIsSuccess(false), 5000);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status) {
            if (err.response.status === 409) {
              setErrorServer(`${err.response.data.detail}`);
            } else
              setErrorServer(
                `エラーが発生しました。${err.response.status}システムの管理者にお問い合わせください。`
              );
          }
        });
  };
  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        <p>アカウントを追加</p>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">ユーザを作成する</DialogTitle>
        <DialogContent>
          <TextField
            required
            autoComplete="one-time-code"
            margin="dense"
            name="username"
            value={formData.username}
            label="ログインID"
            fullWidth
            variant="outlined"
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.username}
            error={Boolean(errors.username)}
          />
          <TextField
            required
            autoComplete="one-time-code"
            margin="dense"
            name="password"
            value={formData.password}
            label="パスワード "
            type="password"
            fullWidth
            variant="outlined"
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.password}
            error={Boolean(errors.password)}
          />
          <TextField
            required
            autoComplete="one-time-code"
            margin="dense"
            name="confirmPassword"
            value={formData.confirmPassword}
            label="パスワードを確認"
            type="password"
            fullWidth
            variant="outlined"
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.confirmPassword}
            error={Boolean(errors.confirmPassword)}
          />
          <TextField
            required
            margin="dense"
            name="fullname"
            value={formData.fullname}
            label="氏名"
            fullWidth
            variant="outlined"
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.fullname}
            error={Boolean(errors.fullname)}
          />
          <TextField
            required
            margin="dense"
            name="email"
            value={formData.email}
            label="メールアドレス"
            type="email"
            fullWidth
            variant="outlined"
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.email}
            error={Boolean(errors.email)}
          />
          <TextField
            margin="dense"
            name="phone_number"
            value={formData.phone_number}
            label="携帯電話番号"
            fullWidth
            variant="outlined"
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.phone_number}
            error={Boolean(errors.phone_number)}
          />
          {errorServer && (
            <p style={{ color: "red", marginTop: "10px" }}>{errorServer}</p>
          )}
          {isSuccess && (
            <p style={{ color: "green", marginTop: "10px" }}>
              作成に成功しました。
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className="mx-4"
            onClick={handleClose}
            variant="outlined"
            sx={{ fontWeight: "bold" }}
          >
            キャンセル
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            autoFocus
            sx={{ paddingX: "30px" }}
          >
            登録
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CreateEmployeeButton;
